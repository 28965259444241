<template>
  <div class="layoutAluno">
    <div class="header">
      <router-link to="/site/home"
        ><img
          src="@/assets/logo-deitada.png"
          alt="APPDATURMA"
          class="logo"
          style="width: 280px"
      /></router-link>
    </div>
    <div
      class="newDesign"
      :class="{ mobile: $vuetify.breakpoint.SmAndDown == true }"
    >
      <v-row class="pa-0 ma-0">
        <v-col cols="12" md="6" class="pa-0">
          <!-- xs  mobile -->
          <div
            v-if="$vuetify.breakpoint.smAndDown == true"
            class="fundoLoginImagem d-block d-md-none"
            :style="{
              backgroundImage:
                `url(` +
                require('@/assets/dash/mobile-login-fundo-imagem.svg') +
                `)`
            }"
          >
            <img
              src="@/assets/dash/login-imagem.svg"
              alt="loginIMage"
              class="loginImagem"
            />
          </div>
          <!-- SmAndUp web  -->
          <div v-else class="fundoLoginImagem d-none d-md-block pa-0">
            <img
              src="@/assets/dash/login-imagem.svg"
              alt="loginIMage"
              class="loginImagem"
            />
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <v-container fill-height>
            <v-layout justify-center align="center">
              <div class="containForm">
                <div class="formulario">
                  <h2>Seja bem vindo!</h2>
                  <div class="form">
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-text-field
                        @change="onchangeEmail"
                        id="emailUser"
                        label="Email"
                        type="text"
                        v-model.trim="email"
                        :error="errorEmail"
                        :rules="validEmail"
                      ></v-text-field>
                      <div class="d-grid grid-col-1-auto vertical-center">
                        <div>
                          <v-text-field
                            id="senhaUser"
                            label="Senha"
                            :type="showPassword ? 'text' : 'password'"
                            v-model="password"
                            :append-icon="
                              showPassword ? 'mdi-eye-off' : 'mdi-eye'
                            "
                            @click:append="showPassword = !showPassword"
                          ></v-text-field>
                        </div>
                      </div>
                    </v-form>
                    <div class="esqueci">
                      <a
                        id="btnEsqueceuSenha"
                        @click="$router.push('/esqueci-minha-senha')"
                        >Esqueceu a senha?
                      </a>
                    </div>
                    <div class="actions">
                      <v-btn
                        :loading="loading"
                        color="#111b47"
                        elevation="1"
                        width="100%"
                        id="btnLogin"
                        :disabled="!valid"
                        @click="processLogin()"
                        >ENTRAR</v-btn
                      >
                    </div>
                  </div>
                </div>
              </div>
            </v-layout>
          </v-container>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import userAuth from "@/middleware/userAuth.js";
import userController from "@/middleware/userController.js";

export default {
  data() {
    return {
      showTemp: true,
      email: "",
      password: "",
      showPassword: false,
      valid: false,
      modalSenhaPerdida: false,
      loading: false,
      errorEmail: false,
      errorPass: false,
      validEmail: [
        v => !!v || "Email é necessário",
        v =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Email inválido"
      ]
    };
  },
  watch: {
    email() {
      //this.validForm();
    }
  },
  methods: {
    onchangeEmail() {
      let email = this.email;
      //testar se é um email valido
      if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        this.errorEmail = true;
      } else {
        this.errorEmail = false;
      }
    },
    validForm() {
      this.$refs.form.validate();
      this.email = this.email.toLowerCase();
    },
    verificaTipoDeUsuario() {
      userController.getUserData().then(response => {
        if (response.data.role == "PARTICIPANTE") {
          this.$router.push("/aluno/dashboard");
        } else {
          this.$root.errorModal(
            "Acesso negado, você não é participante, crie uma conta com outro email para poder acessar."
          );
        }
      });
    },
    processLogin() {
      localStorage.removeItem("JWT_APPDATURMA_ALUNO/token");
      localStorage.removeItem("JWT_APPDATURMA_ALUNO/type");
      this.errorEmail = false;
      this.errorPass = false;
      this.email = this.email.toLowerCase();
      this.validForm();
      if (!this.valid) {
        this.$root.errorModal("Verifique email ou senha");
        return false;
      }
      let self = this;
      let userEmail = this.email;
      let userPass = this.password;
      if (userEmail.length < 5 && !userEmail && userEmail) {
        this.errorEmail = true;
        this.$root.errorModal("Email inválido");
      }
      if (userPass.length > 3 && !userPass) {
        this.errorPass = true;
        this.$root.errorModal("Senha inválido");
      }
      if (this.errorPass || this.errorEmail) {
        this.loading = false;
        return false;
      }
      this.loading = true;
      userAuth
        .login(userEmail, userPass)
        .then(response => {
          let token = response.data;
          self.$store.commit("aluno/setTokenAuth", token);
          localStorage.setItem("JWT_APPDATURMA_ALUNO/token", token.accessToken);
          localStorage.setItem("JWT_APPDATURMA_ALUNO/type", token.tokenType);
        })
        .then(() => {
          this.verificaTipoDeUsuario();
        })
        .catch(err => {
          console.error(err);
          if (err.status == 405) {
            const codigo = err.data.code;
            this.$router.push("/aluno/completar-cadastro/" + codigo);
          } else {
            this.$root.errorModal(err.data.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.newDesign {
  min-height: 90vh;
  .loginImagem {
    max-width: 600px;
    width: 90%;
    object-position: center;
  }
  &.mobile {
    background-image: url("../../assets/dash/login-fundo-imagem.svg");
  }
}
.fundoLoginImagem {
  background-position-y: center;
  background-size: contain;
  background-position: left;
  text-align: center;
  width: 100%;
  .loginImagem {
    //max-width: 450px;
    //width: 80%;
    object-position: center;
  }
  &.d-block {
    background-position: right;
    background-size: auto 100%;
    img {
      max-height: 300px;
    }
  }
  &.d-md-block {
    background-position: left;
    background-size: auto 100%;
    background-image: url("../../assets/dash/login-fundo-imagem.svg");
  }
  &.mobile {
    background-position: right;
  }
}
.layoutAluno {
  width: 100%;
  height: 100vh;
  .header {
    padding: 20px;
    text-align: center;
    color: $primary;
    .logo {
      width: 149px;
    }
  }
  .design {
    background-position: 100% 100%;
    background-size: 100% 100%;
  }
  .containForm {
    align-content: center;
    align-items: center;
    align-self: center;
    text-align: center;
    background-color: white;
    h2 {
      font-size: 45px;
      text-align: center;
      margin-bottom: 30px;
      font-weight: $fontDashRegular;
    }
    .formulario {
      margin: 0 auto;
      //margin-top: -50px; //fixed
      .form {
        max-width: 360px;
        margin: 0 auto;
        .esqueci {
          font-size: 14px;
          text-align: right;
          color: #6f7cb2;
          text-decoration: dotted;
        }
        .actions {
          padding: 20px 0px;
          button {
            background-color: #111b47;
            color: white;
            border-radius: 6px;
          }
        }
      }
    }
  }
}
</style>
